.main {
  padding-top: 20px;
  padding-bottom: 60px;

  .home_top_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    .home_title {
      color: #141316;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 135%;
    }

    .home_again_text {
      position: relative;
      color: #75758b;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;

      &::after {
        content: "";
        position: absolute;
        top: 3px;
        background: url(./assets/svg/rec_arrow.svg);
        background-size: contain;
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
      }
    }
  }

  .slider {
    position: relative;
    margin-bottom: 40px;

    .slick-slider {
      z-index: 1;
    }

    .slick-active {
      z-index: 2;
    }

    .slick-slider-loading {
      display: flex;
      justify-content: space-around;
    }

    .banner_img {
      width: 100%;
      height: 420px !important;
      object-fit: cover;
      border-radius: 30px;
    }

    .slick-arrow {
      top: 85%;
      width: 50px;
      height: 50px;

      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url(./assets/svg/arrow.svg);
        background-repeat: no-repeat;
        background-size: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }

    .slick-next {
      right: 30px;
    }

    .slick-prev {
      left: calc(100% - 150px);
      z-index: 1;

      &::before {
        transform: rotate(180deg);
      }
    }

    .slick-dots {
      height: fit-content;
      width: fit-content;
      top: 85%;
      left: 30px;
      z-index: 1;

      li {
        height: 4px;
        width: 32px;
        margin: 0 4px;

        button {
          width: 32px;
          height: 4px;

          &::before {
            content: "";
            width: 32px;
            height: 4px;
            color: #fff;
            border-radius: 20px;
            background: #fff;
          }
        }
      }

      .slick-active {
        button {
          &::before {
            opacity: 1;
          }
        }
      }
    }
  }

  .top_category {
    margin-bottom: 60px;

    .top_category_wrap {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 24px;
    }
  }

  .rec_product {
    margin-bottom: 60px;

    .rec_product_wrap {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 24px;
      row-gap: 30px;
    }
  }

  .target_banner {
    margin-bottom: 60px;

    img {
      border-radius: 30px;
      object-fit: cover;
      width: 100%;
    }
  }

  .brands {
    position: relative;
    margin-bottom: 60px;

    .brands_slider {
      .slick-list {
        .slick-track {
          height: 170px;
          overflow: hidden;
        }
      }

      .slick-slide {
        margin-left: 24px;
        width: 167px !important;

        &:first-child {
          margin-left: 0;
        }
      }

      .brands_img_wrap {
        position: relative;
        display: block;
        width: 100%;
        height: 160px;
        border-radius: 12px;
        border: 1px solid #e5e7e9;
        background: #fff;
        padding: 0 24px;

        .brands_img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          object-fit: contain;
          width: 100%;
          height: 60px !important;
        }
      }

      // arrow style
      .slick-arrow {
        position: absolute;
        display: block;
        width: 42px;
        height: 42px;
        border-radius: 8px;
        background: #ebf9f6;
        top: -30%;
        right: 0;

        &::before {
          content: "";
          display: block;
          background: url(./assets/svg/arrowBrand.svg);
          background-repeat: no-repeat;
          width: 24px;
          height: 24px;
          margin: auto;
        }
      }

      .slick-prev {
        left: calc(100% - 95px);
        &::before {
          transform: rotate(180deg);
        }
      }
    }
  }

  .hit_week {
    margin-bottom: 60px;

    .hit_week_wrap {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 24px;
      row-gap: 30px;
    }
  }
}
